import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedinIn, faYoutube, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
    return (
        <footer className="bg-[#000622] text-white py-8">
            <div className="container mx-auto px-4">
                <div className="flex flex-wrap -mx-4">
                    <div className="w-full sm:w-1/4 px-4 mb-8 sm:mb-0">
                        <h4 className="text-lg font-semibold mb-4">{localStorage.getItem('glanguage')==='english'?'Products':'उत्पादों'}</h4>
                        <ul>
                            <li className="mb-2"><a href="/" className="hover:underline">{localStorage.getItem('glanguage')==='english'?'Teams':'टीमें'}</a></li>
                            <li className="mb-2"><a href="/" className="hover:underline">{localStorage.getItem('glanguage')==='english'?'Advertising':'विज्ञापन'}</a></li>
                            <li className="mb-2"><a href="/" className="hover:underline">{localStorage.getItem('glanguage')==='english'?'Talent':'प्रतिभा'}</a></li>
                        </ul>
                    </div>
                    <div className="w-full sm:w-1/4 px-4 mb-8 sm:mb-0">
                        <h4 className="text-lg font-semibold mb-4">{localStorage.getItem('glanguage')==='english'?'Policies':'नीतियाँ'}</h4>
                        <ul>
                            <li className="mb-2"><a href="/toc" className="hover:underline">{localStorage.getItem('glanguage')==='english'?'Terms of Service':'सेवा की शर्तें'}</a></li>
                            <li className="mb-2"><a href="/refund" className="hover:underline">{localStorage.getItem('glanguage')==='english'?'Refund Policy':'धनवापसी नीति'}</a></li>
                            <li className="mb-2"><a href="/privacy" className="hover:underline">{localStorage.getItem('glanguage')==='english'?'Privacy Policy':'गोपनीयता नीति'}</a></li>
                        </ul>
                    </div>
                    <div className="w-full sm:w-1/4 px-4 mb-8 sm:mb-0">
                        <h4 className="text-lg font-semibold mb-4">{localStorage.getItem('glanguage')==='english'?'Company':'कंपनी'}</h4>
                        <ul>
                            <li className="mb-2"><a href="/" className="hover:underline">{localStorage.getItem('glanguage')==='english'?'About':'के बारे में'}</a></li>
                            <li className="mb-2"><a href="/contact" className="hover:underline">{localStorage.getItem('glanguage')==='english'?'Contact Us':'संपर्क करें'}</a></li>
                        </ul>
                    </div>
                    <div className="w-full sm:w-1/4 px-4 mb-8 sm:mb-0">
                        <h4 className="text-lg font-semibold mb-4">{localStorage.getItem('glanguage')==='english'?'Follow Us':'हमें यहां फ़ॉलो करें'}</h4>
                        <div className="flex space-x-4 mb-4">
                            <a href="https://www.linkedin.com/company/rnpsoft" rel="noreferrer" target="_blank" className="hover:text-gray-400">
                                <FontAwesomeIcon icon={faLinkedinIn} />
                            </a>
                            <a href="https://www.youtube.com/@RnPsoft" rel="noreferrer" target="_blank" className="hover:text-gray-400">
                                <FontAwesomeIcon icon={faYoutube} />
                            </a>
                            <a href="https://twitter.com/RnPsoft" rel="noreferrer" target="_blank" className="hover:text-gray-400">
                                <FontAwesomeIcon icon={faTwitter} />
                            </a>
                            <a href="https://www.instagram.com/rnpsoft/" rel="noreferrer" target="_blank" className="hover:text-gray-400">
                                <FontAwesomeIcon icon={faInstagram} />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
