import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const LocationWatcher = () => {
  const location = useLocation();

  useEffect(() => {
    if(location.pathname==='/video'){
    }else{
      fetch("https://abiv.rnpsoft.com/terminat", {
        method: "POST",
        headers: {
          Accept: "application/form-data",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({sessionid:localStorage.getItem('sessionid')})
      }).then((res) => res.json()).then((data) => { console.log(data) });
    }
  }, [location]);

  return null;
};

export default LocationWatcher;