import React,{useState} from "react";
import job from './job.png'
import attend from './attend.png'
import { useNavigate } from "react-router-dom";
import Modal from 'react-modal'
import Login from './LoginSignup2/LoginSignup2'
import Signin from './LoginSignup2/SignUp'
const Component = () => {
  const navigate = useNavigate();
  const [isModalOpen1, setIsModalOpen1] = useState(false);
      const [login,setsignin]=useState(false)
          const [signin,signup]=useState(false)

  return (
    <div className="relative w-full min-h-screen bg-white" style={{ background: "linear-gradient(180deg, rgb(1,11,120) 0%, rgb(0,0,0) 100%)" }}>
            
      <div className="relative inset-0 flex flex-col items-center justify-center">
      <div className="mt-40 relative text-white text-6xl font-extrabold text-center">
      {localStorage.getItem('glanguage')==='english'?'Test yourself!':'खुद को परीक्षण करें!'}
      </div>
        <div className="mt-20 flex flex-col md:flex-row items-center justify-center space-y-8 md:space-y-0 md:space-x-8">
          
<div>
<div className=" text-white text-4xl font-extrabold text-center">{localStorage.getItem('glanguage')==='english'?'Live Interview':'लाइव साक्षात्कार'}</div>
<p className="mt-4 text-white text-lg text-center">
{localStorage.getItem('glanguage')==='english'&&<>
          Participate in our live AI-based interview to <br />
          assess and enhance your knowledge <br />
          and preparation.
          </>}
          {localStorage.getItem('glanguage')==='hindi'&&<>
          हमारे लाइव AI आधारित साक्षात्कार में भाग लें <br />
          अपने ज्ञान और तैयारी का मूल्यांकन करें और बढ़ावा दें।
          </>}
        </p>
          <div className="mt-10 w-80 h-80 bg-[#010c16] rounded-lg border border-dashed border-[#d9d9d9] flex flex-col items-center justify-center cursor-pointer relative">
            <img className="w-60 h-60" alt="Online interview" src={job} />
            <div className="mt-4 text-white text-2xl font-semibold text-center">{localStorage.getItem('glanguage')==='english'?'Join Interview':'साक्षात्कार में शामिल हों'}</div>
            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white text-xl font-bold opacity-0 hover:opacity-100 transition-opacity duration-300">
              Coming Soon
            </div>
          </div>
          </div>
          <div>
          <div className=" text-white text-4xl font-extrabold text-center">{localStorage.getItem('glanguage')==='english'?'Answersheet Exam':'उत्तरपुस्तिका परीक्षा'}</div>
          <p className="mt-4 text-white text-lg text-center">
          {localStorage.getItem('glanguage')==='english'&&<>
          Take our Answersheet Exam to <br />
          evaluate your subjective knowledge <br />
          and receive a detailed performance report.
          </>}
          {localStorage.getItem('glanguage')==='hindi'&&<>
          हमारी उत्तरपुस्तिका परीक्षा दें <br />
          अपने विषयवार ज्ञान का मूल्यांकन करें <br />
          और एक विस्तृत प्रदर्शन रिपोर्ट प्राप्त करें।
          </>}
        </p>
          <div className="mt-10 w-80 h-80 bg-[#010b78] rounded-lg border border-dashed border-white shadow-md flex flex-col items-center justify-center cursor-pointer" onClick={() => {
            if(!localStorage.getItem('auth-token')){
              setIsModalOpen1(true)
              setsignin(true);}else
            navigate('/selectscreen')}}>
            <img className="w-36 h-36" alt="School learn study" src={attend} />
            <div className="mt-4 text-white text-2xl font-semibold text-center">{localStorage.getItem('glanguage')==='english'?'Attend Exam':'परीक्षा में भाग लें'}</div>
          </div>
          </div>
        </div>
        
      </div>
      <Modal
                isOpen={isModalOpen1}
                onRequestClose={()=>{
                    setIsModalOpen1(false)
                }}
                contentLabel="Record Case Proceeding"
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        width: '400px',
                        padding: '20px',
                        borderRadius: '10px',
                        boxShadow: '0 5px 15px rgba(0,0,0,0.3)',
                        zIndex: '60'
                    },
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.75)',
                        zIndex: '59'
                    }
                }}
            >
               {login && !signin && (
<Login islogin={setsignin} issignup={signup} setmodel={setIsModalOpen1}></Login> 
            )            
            }
            {signin && (
<Signin setsign={signup} setmodel={setIsModalOpen1}/> 

                )
            }
            </Modal>
    </div>
  );
};
export default Component