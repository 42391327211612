import React from 'react';
import './App.css';
import image1 from './1.jpg';
import image2 from './2.jpg';
import image3 from './3.jpg';
import image4 from './4.jpg';

const WhyABIV = () => {
  return (
    <div className="why-abiv-container">
      <div className="content-container">
        <h2 className="title">{localStorage.getItem('glanguage')==='english'?'Why ABIV':'क्यों ABIV'}</h2>
        <p className="description">
          {localStorage.getItem('glanguage')==='english' ? 
            'ABIV is the one stop platform to study anything from anywhere, all you need is just a pdf or a query to hit and use our ai techniques to help them study.' : 
            'ABIV एक ऐसा प्लेटफार्म है जहाँ से आप कहीं से भी कुछ भी पढ़ सकते हैं, आपको बस एक पीडीएफ या एक क्वेरी की आवश्यकता है और हमारे एआई तकनीकों का उपयोग करके अध्ययन करने में मदद करें।'}
        </p>

        <div className="grid-container">
          <div className="ccard card-left">
            <div className="ccard-content">
              <h3 className="ccard-title">{localStorage.getItem('glanguage')==='english'?'Text to Video':'पाठ से वीडियो'}</h3>
              <p className="ccard-description">
                {localStorage.getItem('glanguage')==='english' ? 
                  'Converts any text to video with enriching user experience and study notes.' : 
                  'किसी भी पाठ को वीडियो में बदलें, उपयोगकर्ता अनुभव और अध्ययन नोट्स को समृद्ध करें।'}
              </p>
            </div>
            <img src={image4} alt="Text to video" className="image" />
          </div>

          <div className="ccard card-right">
            <div className="ccard-content">
              <h3 className="ccard-title">{localStorage.getItem('glanguage')==='english'?'MCQs':'बहुविकल्पी प्रश्न'}</h3>
              <p className="ccard-description">
                {localStorage.getItem('glanguage')==='english' ? 
                  'Get curated MCQs available for you while you watch the animation and make yourself prepared.' : 
                  'एनिमेशन देखते समय आपके लिए उपलब्ध क्यूरेटेड बहुविकल्पी प्रश्न प्राप्त करें और खुद को तैयार करें।'}
              </p>
            </div>
            <img src={image1} alt="MCQs" className="image" />
          </div>

          <div className="ccard ccard-left">
            <img src={image3} alt="Interview" className="image" />
            <div className="ccard-content">
              <h3 className="ccard-title">{localStorage.getItem('glanguage')==='english'?'DOUBTS':'संदेह'}</h3>
              <p className="ccard-description">
                {localStorage.getItem('glanguage')==='english' ? 
                  'Get Live Doubt Assistance while you study to make your topic enriching.' : 
                  'अध्ययन करते समय लाइव संदेह सहायता प्राप्त करें ताकि आपका विषय समृद्ध हो सके।'}
              </p>
            </div>
          </div>

          <div className="ccard ccard-right">
            <img src={image2} alt="Doubts" className="image" />
            <div className="ccard-content">
              <h3 className="ccard-title">{localStorage.getItem('glanguage')==='english'?'INTERVIEW':'साक्षात्कार'}</h3>
              <p className="ccard-description">
                {localStorage.getItem('glanguage')==='english' ? 
                  'Upcoming Feature to help you cater yourself for Exam Preparation!' : 
                  'परीक्षा की तैयारी के लिए खुद को तैयार करने में मदद करने के लिए आगामी सुविधा!'}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyABIV;
