import React, { useState } from 'react';
import { FaEyeSlash, FaEye } from "react-icons/fa";
import './LoginSignup2.css';
import eclipse4 from "../../assets/Ellipse 4.png";
import eclipse2 from "../../assets/Ellipse 2.png";
import eclipse3 from "../../assets/Ellipse 3.png";
import eclipse5 from "../../assets/Ellipse 5.png";
import { useNavigate } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';

const LoginSignUp2 = ({ islogin, issignup,setmodel }) => {
  const [password, setPassword] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const navigate = useNavigate();
  const [formInput, setFormInput] = useState({
    email: '',
    password: ''
  });

  const handleUserInput = (name, value) => {
    setFormInput({
      ...formInput,
      [name]: value,
    });
  };

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  return (
    <>
      {/* Background eclipse images */}
      <div
        className="w-full h-auto relative rounded-3xl bg-[#d9d9d9] bg-opacity-95 shadow dark:border mt-0 sm:max-w-lg xl:p-0 lg:px-16">
        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
          <div className="flex items-center justify-center">
          <GoogleOAuthProvider clientId="594536791781-6640scot0ufth8ol2onnnrg3tqo088ih.apps.googleusercontent.com">
            <GoogleLogin
              onSuccess={credentialResponse => {
                console.log(credentialResponse);
                const token = credentialResponse.credential;
                console.log(token);
                fetch('https://www.googleapis.com/oauth2/v3/tokeninfo?id_token=' + token)
                  .then(response => response.json())
                  .then(data => {
                    console.log('User Info:', data);
                    const { email } = data;
                    console.log('Email:', email);
                    localStorage.setItem('user-data', JSON.stringify({ email }));
                    fetch('https://abiv.rnpsoft.com/login', {
                      method: 'POST',
                      headers: {
                        'Content-Type': 'application/json',
                      },
                      body: JSON.stringify({ email, password: 'google', signinwithgoogle: true })
                    }).then(response => response.json())
                      .then(data => {
                        if (data.success) {
                          localStorage.setItem('auth-token', data.token);
window.location.reload();
setmodel(false)

} else {
                          alert('Please try different method to login');
                        }

                      })
                      .catch((error) => {
                        console.error('Error:', error);
                      });

                  })
                  .catch(error => {
                    console.error('Error fetching user info:', error);
                  });
                // You can decode the token to get user information if needed
              }}
              onError={() => {
                console.log('Login Failed');
              }}
            />
          </GoogleOAuthProvider>
          </div>
          <div className="flex items-center justify-center">
            <strong>OR</strong>
            </div>
          <h1 className="text-xl text-center font-bold leading-tight tracking-tight text-black md:text-2xl dark:text-black">
            {localStorage.getItem('glanguage') === 'english' ? 'Login' : 'लॉग इन'}
          </h1>
          <p className="text-sm text-center font-light text-black dark:text-black">
            {localStorage.getItem('glanguage')==='english'?'Don\'t have an account yet?':'अभी तक खाता नहीं बनाया है?'}{" "}
            <div className="font-medium text-primary-600 hover:underline dark:text-primary-500" onClick={() => { issignup(true); islogin(false); localStorage.setItem('loginactive', 'False'); localStorage.setItem('signup', 'True') }}>
              {localStorage.getItem('glanguage') === 'english' ? 'Sign up' : 'साइन अप करें'}
            </div>
          </p>
          <form className="space-y-4 md:space-y-6" onSubmit={async (e) => {
            e.preventDefault();
            let responseData;
            await fetch("https://abiv.rnpsoft.com/login", {
              method: "POST",
              headers: {
                Accept: "application/form-data",
                "Content-Type": "application/json",
              },
              body: JSON.stringify(formInput)
            }).then((res) => res.json()).then((data) => { responseData = data });

            if (responseData.success) {
              localStorage.setItem("auth-token", responseData.token);
              islogin(false)
setmodel(false)
              localStorage.setItem("user-data", JSON.stringify(
                {
                  email: formInput.email,
                  password: formInput.password
                }
              ))
              localStorage.setItem('loginactive', 'False');
              window.location.reload();
              if (window.location.pathname === '/')
                navigate('/');
            } else {
              console.log(responseData);
            }

          }}>
            <div>
              <label htmlFor="email" className="block mb-2 text-sm font-medium text-black dark:text-black">
                {localStorage.getItem('glanguage') === 'english' ? 'Email' : 'ईमेल'}
              </label>
              <input
                type="email"
                name="email"
                id="email"
                value={formInput.email}
                onChange={(e) => handleUserInput('email', e.target.value)}
                className="text-black rounded-lg block w-full p-2.5"
                style={{ background: "rgba(255, 255, 255, 0)", border: "2px solid black" }} // Match background color
                required
              />
            </div>
            <div>
              <label className="block mb-2 -mt-4 text-sm font-medium text-black dark:text-black">
                {localStorage.getItem('glanguage') === 'english' ? 'Password' : 'पासवर्ड'}{" "}
                <button type="button" style={{ display: "flex" }} className="float-right" onClick={toggleVisibility}>
                  {isVisible ? <FaEyeSlash className="mx-1 mt-1" /> : <FaEye className="mx-1 mt-1" />}
                  {localStorage.getItem('glanguage') === 'english' ? 'Hide' : 'छुपाएं'}
                </button>
              </label>
              <input
                value={formInput.password}
                onChange={(e) => handleUserInput('password', e.target.value)}
                type={isVisible ? 'text' : 'password'}
                className="text-black rounded-lg block w-full p-2.5 "
                style={{ background: "rgba(255, 255, 255, 0)", border: "2px solid black" }} // Match background color
                required
              />
            </div>
            <div className="flex items-center justify-between float-right">
              <a href="/forgot" className="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500 -mt-5 underline text-black">
              {localStorage.getItem('glanguage') === 'english' ? 'Forgot your password?' : 'पासवर्ड भूल गए?'}
              </a>
            </div>
            <button
              type="submit"
              className="w-full text-white font-medium rounded-full text-sm px-5 py-2.5 text-center"
              style={{ background: "black" }}
            >
              {localStorage.getItem('glanguage') === 'english' ? 'Log in' : 'लॉग इन'}
            </button>
          </form>
          
        </div>
      </div>
    </>
  );
};

export default LoginSignUp2;
