import React from 'react';
import coin from "../../assets/Coins.png";
import {load} from '@cashfreepayments/cashfree-js'
import axios from 'axios';
import Modal from 'react-modal';
import { useState } from 'react';
import Login from '../LoginSignup2/LoginSignup2'
import Signin from '../LoginSignup2/SignUp'
function PricingTable() {
  let cashfree;
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [login, setlogin1] = useState(false);
  const [signin, setsignin] = useState(false);

  let insitialzeSDK = async function () {

    cashfree = await load({
      mode: "production",
    })
  }

  insitialzeSDK()
  const getSessionId = async () => {
    try {
      await fetch("https://abiv.rnpsoft.com/fetchdata",{
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({'email':JSON.parse(localStorage.getItem('user-data')).email}),
      }).then(response => response.json()).then(data => {localStorage.setItem('username',data.data.firstName+' '+data.data.lastName)})
      let res = await axios.post("https://abiv.rnpsoft.com/payment",{
        amount:Number(localStorage.getItem('amount'))+Math.ceil(0.18*Number(localStorage.getItem('amount'))),
        customeremail:JSON.parse(localStorage.getItem('user-data')).email,
        customername:localStorage.getItem('username')
      }
        )
      if(res.data && res.data.payment_session_id){
        console.log(res.data)
        localStorage.setItem('orderId',res.data.order_id)
        return res.data.payment_session_id
      }


    } catch (error) {
      console.log(error)
    }
  }

  const verifyPayment = async () => {
    try {
      
      let res = await axios.post("https://abiv.rnpsoft.com/verify", {
        orderId: localStorage.getItem('orderId')
      })
      if(res && res.data){
        if(res.data.length>0){
        if(res.data[0].payment_completion_time!=null){
        alert("Payment Successfull")
        let email=JSON.parse(localStorage.getItem('user-data')).email;
        fetch("https://abiv.rnpsoft.com/addcoins",{
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({'email':email,'coins':Number(localStorage.getItem('amount'))==149?50:Number(localStorage.getItem('amount'))==249?100:210}),
        }).then(response => response.json())
        .then(data => {
          window.location.reload();
        })
      }else{
        alert("Payment Failed")
      }}else {
        alert("Payment Failed")
        }
      }

    } catch (error) {
      console.log(error)
    }
  }

  const handleClick = async () => {
    try {
      let sessionId = await getSessionId()
      let checkoutOptions = {
        paymentSessionId : sessionId,
        redirectTarget:"_modal",
      }

      cashfree.checkout(checkoutOptions).then((res) => {
        console.log("payment initialized")

        verifyPayment(localStorage.getItem('orderId'))

      })


    } catch (error) {
      console.log(error)
    }
  }
  const plans = [
    {
      title: localStorage.getItem('glanguage')==='english'?"BASIC":'मूलभूत',
      price: localStorage.getItem('glanguage')==='english'?"₹149":'₹१४९',
      period: localStorage.getItem('glanguage')==='english'?'50 credits':"/५० क्रेडिट",
      features: [
        { text: localStorage.getItem('glanguage')==='english'?"Notes":'नोट्स', note: null },
        { text: localStorage.getItem('glanguage')==='english'?"MCQ Test":'एमसीक्यू टेस्ट', note: null },
        { text: localStorage.getItem('glanguage')==='english'?"Playback":'प्लेबैक', note: null },
        { text: localStorage.getItem('glanguage')==='english'?"Doubt Session":'संदेह सत्र', note: null },
        { text: localStorage.getItem('glanguage')==='english'?"Feedback Report":'प्रतिक्रिया रिपोर्ट', note: null },
      ],
      badge: localStorage.getItem('glanguage')==='english'?"BASIC":'मूलभूत',
      badgeColor: "bg-yellow-200 text-blue-500",
      borderColor: "border-gray-500",
    },
    {
      title: localStorage.getItem('glanguage')==='english'?"STANDARD":'मानक',
      price: localStorage.getItem('glanguage')==='english'?"₹249":'₹२४९',
      period: localStorage.getItem('glanguage')==='english'?"/100 credits":"/१०० क्रेडिट",
      features: [
        { text: localStorage.getItem('glanguage')==='english'?"Popular":'लोकप्रिय', note: null },
        { text: localStorage.getItem('glanguage')==='english'?"Save Rs. 50":'50 रुपये बचाएं', note: null },
        { text: localStorage.getItem('glanguage')==='english'?"Notes":'नोट्स', note: null },
        { text: localStorage.getItem('glanguage')==='english'?"MCQ Test":'एमसीक्यू टेस्ट', note: localStorage.getItem('glanguage')==='english'?"(Moderate)":'(मध्यम)' },
        { text: localStorage.getItem('glanguage')==='english'?"Playback":'प्लेबैक', note: null },
        { text: localStorage.getItem('glanguage')==='english'?"Doubt Session":'संदेह सत्र', note: null },
        { text: localStorage.getItem('glanguage')==='english'?"Feedback Report":'प्रतिक्रिया रिपोर्ट', note: null },
      ],
      badge: localStorage.getItem('glanguage')==='english'?"STANDARD":'मानक',
      badgeColor: "bg-yellow-200 text-blue-500",
      borderColor: "border-yellow-400",
    },
    {
      title: localStorage.getItem('glanguage')==='english'?"PREMIUM":'प्रीमियम',
      price: localStorage.getItem('glanguage')==='english'?"₹499":'₹४९९',
      period: localStorage.getItem('glanguage')==='english'?"/210 credits":"/२१० क्रेडिट",
      features: [
        { text: localStorage.getItem('glanguage')==='english'?"Extra 10 Credits":'अतिरिक्त 10 क्रेडिट', note: null },
        { text: localStorage.getItem('glanguage')==='english'?"Notes":'नोट्स', note: null },
        { text: localStorage.getItem('glanguage')==='english'?"MCQ Test":'एमसीक्यू टेस्ट', note: localStorage.getItem('glanguage')==='english'?"(Plentiful)":'(प्रचुर मात्रा में)' },
        { text: localStorage.getItem('glanguage')==='english'?"Playback":'प्लेबैक', note: null },
        { text: localStorage.getItem('glanguage')==='english'?"Doubt Session (No Limit)":'संदेह सत्र (कोई सीमा नहीं)', note: null },
        { text: localStorage.getItem('glanguage')==='english'?"Feedback Report":'प्रतिक्रिया रिपोर्ट', note: null },
      ],
      badge: localStorage.getItem('glanguage')==='english'?"PREMIUM":'प्रीमियम',
      badgeColor: "bg-yellow-200 text-blue-500",
      borderColor: "border-gray-500",
    },
  ];

  return (
    <div className="video-page-background py-12 text-center flex flex-col justify-center items-center text-white" id="pricing">
      <h1 className="text-4xl md:text-5xl font-bold font-[poppins]">{localStorage.getItem('glanguage')==='english'?'Choose The Plan That’s Right For You':'वह योजना चुनें जो आपके लिए सही हो'}</h1>
      <p className="text-lg mt-4 font-medium grad font-[poppins] bg-gradient-to-b from-[#969696] to-[#303030]  bg-clip-text">{localStorage.getItem('glanguage')==='english'?'Affordable and scalable options for everyone.':'सभी के लिए किफायती और स्केलेबल विकल्प।'}</p>
      <div className="flex flex-col md:flex-row w-[90%] md:w-[80%] justify-center gap-6 md:gap-16 items-center lg:bg-opacity-30 lg:bg-[#FFFFFF] mt-10 py-10 px-4 md:px-8 rounded-3xl">
        {plans.map((plan, index) => (
          <div
            key={index}
            className={`relative bg-[#000000] text-white shadow-lg rounded-lg p-8 w-full md:w-[28%] ${
              index === 1 ? 'md:scale-110 md:z-10' : 'bg-opacity-70'
            } transition-all duration-300 `}
          >
            <span className={`absolute top-0 left-0 text-xs ml-4 mt-4 px-3 py-1 rounded-full ${plan.badgeColor}`}>
              {plan.badge}
            </span>
            <div className="flex items-center justify-between mt-8">
              <h2 className="lg:text-3xl  font-bold text-white text-left">
                {plan.price}
                <span className="text-2xl"> {plan.period} </span>
              </h2>
              <img src={coin} alt="Coin" className="w-6 h-6 ml-2" />
            </div>
            <p className="text-lg mt-3 text-left">{localStorage.getItem('glanguage')==='english'?"What's included":'क्या शामिल है'}</p>
            <ul className="mt-4 space-y-3 text-sm text-left">
              {plan.features.map((feature, i) => (
                <li key={i} className="flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill='white'
                    className="bi bi-check-circle"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zM8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0z" />
                    <path d="M10.97 5.97a.75.75 0 0 1 1.07 1.05L7.477 11.6l-3.5-3.5a.75.75 0 1 1 1.06-1.06l2.42 2.42 3.48-3.48z" />
                  </svg>
                  <span className="ml-3">
                    {feature.text}
                    {feature.note && <span className="text-sm text-gray-400">{feature.note}</span>}
                  </span>
                </li>
              ))}
            </ul>
            <button className="mt-6 w-full bg-white text-blue-500 text-sm font-semibold py-3 rounded border-2 border-blue-500 hover:bg-gray-100 transition" onClick={()=>{
                                            if(!localStorage.getItem('auth-token')){
                                              setIsModalOpen1(true)
                                              setlogin1(true);
                                            }else{
              let p=plan.price;
            p=p.replace('₹','');
            p=p.trim();
            localStorage.setItem('amount',p);
            handleClick()}
            }}>
              {localStorage.getItem('glanguage')==='english'?'Continue 🡥':'जारी रखें 🡥'}
            </button>
          </div>
        ))}
      </div>
      <Modal
                isOpen={isModalOpen1}
                onRequestClose={()=>{
                    setIsModalOpen1(false)
                }}
                contentLabel="Record Case Proceeding"
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        width: '400px',
                        padding: '20px',
                        borderRadius: '10px',
                        boxShadow: '0 5px 15px rgba(0,0,0,0.3)',
                        zIndex:60,
                    },
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.75)',
                        zIndex:59
                    },
                    zIndex: 1000
                }}
            >
               {login && !signin && (
<Login islogin={setlogin1} issignup={setsignin} setmodel={setIsModalOpen1}></Login> 
            )            
            }
            {signin && (
<Signin setsign={setsignin} setmodel={setIsModalOpen1}/> 

                )
            }
            </Modal>
    </div>
  );
}

export default PricingTable;
