import React from "react";

const TermsAndConditions = () => {
    return (
        <div className="bg-white flex justify-center items-center min-h-screen">
            <div className="bg-gradient-to-b from-blue-900 to-black w-full h-screen flex justify-center items-center">
                <div className="relative w-4/5 h-4/5 bg-blue-800 rounded-2xl border-4 border-white shadow-lg p-10">
                    <h1 className="font-semibold text-white text-5xl text-center mb-10">
                        Terms and Conditions
                    </h1>
                    <div className="font-normal text-white text-xl leading-relaxed overflow-y-scroll h-3/4 custom-scrollbar">
                        <p className="whitespace-pre-line">
                            Terms & Conditions<br /><br />
                            1. Credits and Usage<br />
                                i) ₹249 provides 100 credit coins.<br />
                                ii) Credits are deducted automatically based on usage<br />
                                iii) Unused credits are non-refundable and non-transferable.<br />
                                iv) Normal Class Mode (Video View Mode)<br />
                                    a) 1 credit coin provides 1 to 1.5 minutes of watch time.<br />
                                    b) Includes MCQs, key points, and notes for comprehensive learning.<br />
                                v) Examination Mode<br />
                                    a) 4 credit coins per test attempt.<br />
                                    b) Includes the entire test, detailed feedback, and a performance report.<br /><br />
                            2. AI-Generated Content<br />
                                i) All content on ABIV is AI-generated, including videos, notes, MCQs, and feedback.<br />
                                ii) While efforts are made to ensure accuracy, the content may occasionally contain errors.<br />
                                iii) Users are advised to independently verify critical information.<br /><br />
                            3. Evaluation Feedback and Reports<br />
                                i) The feedback and performance reports generated by ABIV are purely AI-based.<br />
                                ii) Users are advised to consider these evaluations as guidance and exercise discretion when relying on them for decision-making.<br /><br />
                            4. Ethical Use Policy<br />
                                i) ABIV content must not be used for unethical or unlawful purposes.<br />
                                ii) Activities such as plagiarism, fraud, or violations of law are strictly prohibited.<br /><br />
                            5. Liability Disclaimer<br />
                                i) ABIV is not responsible for decisions made based on AI-generated content.<br />
                                ii) The platform provides educational content without guaranteeing specific results.<br /><br />
                            6. General Terms<br />
                                i) Users must keep their account credentials secure.<br />
                                ii) Violation of these terms may result in account suspension without refund.<br />
                                iii) ABIV reserves the right to update these terms without prior notice.<br /><br />
                            7. AI-Generated Images Disclaimer<br />
                                i) All images generated by ABIV are purely AI-created and may depict unreal or fictional scenarios.<br />
                                ii) These images do not represent real people, places, or events and should not be interpreted as factual.<br />
                                iii) Any resemblance to actual persons, communities, religions, or entities is purely coincidental and unintentional.<br />
                                iv) ABIV does not intend to mislead, offend, or harm any individual or group in any way.<br />
                                v) Users are advised not to rely on AI-generated visuals as accurate representations of reality.<br />
                                vi) Misuse of AI-generated images for unethical, defamatory, or harmful purposes is strictly prohibited.<br /><br />
                            By using ABIV, you acknowledge and agree to these terms.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TermsAndConditions;
/* toc.css */
