import React from 'react';
import { FaQuestionCircle } from "react-icons/fa";
import faqimg from '../assets/Frame 1321314808.png';

const Page8 = () => {
    return (
        <>
            <section className="question py-10 bg-gradient-to-b from-[#000D3F] to-black">
                <div className="questionheading">
                    <h1 className="text-center text-3xl md:text-4xl lg:text-6xl font-bold text-white">{localStorage.getItem('glanguage')==='english'?'Popular Questions':'महत्वपूर्ण प्रश्न'}</h1>
                </div>
                <div className="questioncard grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 my-10 px-4">
                    {/* Card 1 */}
                    <div className="card1 block p-6 bg-transparent rounded-lg shadow">
                        <div className="flex items-center mb-2">
                            <FaQuestionCircle className='text-blue-500 text-2xl md:text-3xl mr-2' />
                            <h5 className="text-base md:text-lg font-bold tracking-tight text-white">{localStorage.getItem('glanguage')==='english'?'What is ABIV?':'ABIV क्या है?'}</h5>
                        </div>
                        <p className="font-normal text-gray-300">{localStorage.getItem('glanguage')==='english'?'ABIV is an AI-powered website developed by RnPsoft that can convert text or PDF documents into animations. It also features exam mode and interview mode.':'ABIV एक AI-संचालित वेबसाइट है जिसे RnPsoft द्वारा विकसित किया गया है जो टेक्स्ट या PDF दस्तावेज़ों को एनिमेशन में बदल सकता है। इसमें परीक्षा मोड और साक्षात्कार मोड भी शामिल हैं।'}</p>
                    </div>
                    {/* Card 2 */}
                    <div className="card2 block p-6 bg-transparent rounded-lg shadow">
                        <div className="flex items-center mb-2">
                            <FaQuestionCircle className='text-blue-500 text-2xl md:text-3xl mr-2' />
                            <h5 className="text-base md:text-lg font-bold tracking-tight text-white">{localStorage.getItem('glanguage')==='english'?'What languages ABIV support?':'ABIV किन भाषाओं का समर्थन करता है?'}</h5>
                        </div>
                        <p className="font-normal text-gray-300">{localStorage.getItem('glanguage')==='english'?'ABIV supports English,Hindi and Hinglish.':'ABIV अंग्रेजी, हिंदी और हिंग्लिश का समर्थन करता है।'}</p>
                    </div>
                    {/* Card 3 */}
                    <div className="card3 block p-6 bg-transparent rounded-lg shadow">
                        <div className="flex items-center mb-2">
                            <FaQuestionCircle className='text-blue-500 text-2xl md:text-3xl mr-2' />
                            <h5 className="text-base md:text-lg font-bold tracking-tight text-white">{localStorage.getItem('glanguage')==='english'?'How does Abiv help in studying?':'ABIV पढ़ाई में कैसे मदद करता है?'}</h5>
                        </div>
                        <p className="font-normal text-gray-300">{localStorage.getItem('glanguage')==='english'?'It provides better notes, real-time doubt solving, and an AI teacher.':'यह बेहतर नोट्स, वास्तविक समय में संदेह समाधान और एक एआई शिक्षक प्रदान करता है।'}</p>
                    </div>
                    {/* Card 4 */}
                    <div className="card4 block p-6 bg-transparent rounded-lg shadow">
                        <div className="flex items-center mb-2">
                            <FaQuestionCircle className='text-blue-500 text-2xl md:text-3xl mr-2' />
                            <h5 className="text-base md:text-lg font-bold tracking-tight text-white">{localStorage.getItem('glanguage')==='english'?'Does Abiv have an exam mode?':'क्या ABIV के पास परीक्षा मोड है??'}</h5>
                        </div>
                        <p className="font-normal text-gray-300">{localStorage.getItem('glanguage')==='english'?'Yes, it includes MCQs and answer-sheet-based exams.':'हां, इसमें एमसीक्यू और उत्तर-पुस्तिका-आधारित परीक्षाएं शामिल हैं।'}</p>
                    </div>
                    {/* Card 5 */}
                    <div className="card5 block p-6 bg-transparent rounded-lg shadow">
                        <div className="flex items-center mb-2">
                            <FaQuestionCircle className='text-blue-500 text-2xl md:text-3xl mr-2' />
                            <h5 className="text-base md:text-lg font-bold tracking-tight text-white">{localStorage.getItem('glanguage')==='english'?'Can Abiv solve doubts instantly?':'क्या ABIV तुरंत शंकाओं का समधान कर सकता है?'}</h5>
                        </div>
                        <p className="font-normal text-gray-300">{localStorage.getItem('glanguage')==='english'?'Yes, through direct voice interaction.':'हाँ, सीधे ध्वनि संपर्क के माध्यम से।'}</p>
                    </div>
                    {/* Card 6 */}
                    <div className="card6 block p-6 bg-transparent rounded-lg shadow">
                        <div className="flex items-center mb-2">
                            <FaQuestionCircle className='text-blue-500 text-2xl md:text-3xl mr-2' />
                            <h5 className="text-base md:text-lg font-bold tracking-tight text-white">{localStorage.getItem('glanguage')==='english'?'What are the pricing plans for ABIV?':'ABIV के लिए मूल्य निर्धारण योजनाएँ क्या हैं?'}</h5>
                        </div>
                        <p className="font-normal text-gray-300">{localStorage.getItem('glanguage')==='english'?'ABIV requires credits to work, which can be purchased using UPI, credit, and debit cards. For detailed pricing information, please contact our sales team.':'ABIV को काम करने के लिए क्रेडिट की आवश्यकता होती है, जिसे UPI, क्रेडिट और डेबिट कार्ड का उपयोग करके खरीदा जा सकता है। विस्तृत मूल्य निर्धारण जानकारी के लिए कृपया हमारी बिक्री टीम से संपर्क करें।'}</p>
                    </div>
                </div>
                <div className="askquestion mx-auto bg-[#EFEDE0] rounded-lg p-6 text-center max-w-xs md:max-w-md lg:max-w-lg">
                    <h6 className="text-blue-700 font-bold mb-2">{localStorage.getItem('glanguage')==='english'?'Still have questions?':'अभी भी प्रश्न हैं?'}</h6>
                    <p className="text-black">{localStorage.getItem('glanguage')==='english'?'Can’t find the answer you\'re looking for? Please chat with our friendly team.':'क्या आपको वह उत्तर नहीं मिल रहा है जिसकी आप तलाश कर रहे हैं? कृपया हमारी मित्रवत टीम से चैट करें।'}</p>
                    <button type="button" className="bg-blue-700 text-white mt-4 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5" onClick={()=>{
                        window.open('mailto:support@abiv.in', '_blank');
                    }}>{localStorage.getItem('glanguage')==='english'?'Get in Touch':'संपर्क करें'}</button>
                </div>
            </section>
        </>
    );
}

export default Page8;
